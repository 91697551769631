import React, { useState } from "react"
import ModalVideo from "react-modal-video"
import "react-modal-video/css/modal-video.min.css";
import { StaticQuery, graphql } from "gatsby"

const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [videoId, setvideoId] = useState(null);

  const openModal = (videoId) => {
    setvideoId(videoId)
    setIsOpen(true);
  }
  return (
    <StaticQuery
      query={graphql`
  query {
    allMoviesYaml {
      edges {
        node {
          media {
            title
            video
            description
          }
        }
      }
    }
  }
`}
      render={data => (
        <section id="tg-movies" className="tg-sectionspace tg-haslayout">
          <div className="container">
            <div className="row">
              <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
                <div className="tg-sectionhead">
                  <div className="tg-sectionheading">
                    <h2>Nasze filmy</h2>
                  </div>
                </div>
              </div>
              <ModalVideo isOpen={isOpen} videoId={videoId} onClose={() => setIsOpen(false)} />
              <div className="grid-video">
                {
                  data.allMoviesYaml.edges.map(({ node: { media: { title, video, description } } }, index) => {
                    const imageUrl = `images/videos/${video}.jpg`;
                    return (
                      <div className="grid-video__item" key={index}>
                          <div className="thumbnail__image-wrapper" onClick={() => openModal(video)}>
                            <div className="logo-wrapper">
                              <i class="fa fa-youtube-play"></i>
                            </div>
                            <img src={imageUrl} alt={title} onClick={() => openModal(video)} />
                            <div className="thumbnail__title">
                              <p>{title}</p>
                            </div>
                          </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </section>)}
    />
  )
}
export default About
