import React from 'react'
import { StaticQuery, graphql } from "gatsby"

const MediaAboutUs = () => (
  <StaticQuery
    query={graphql`
    query {
      allMediasYaml {
        edges {
          node {
            media {
              image
              title
              url
              description
            }
          }
        }
      }
    }
  `}

    render={data => (
      <section id="tg-media" className="tg-sectionspace tg-haslayout">
        <div className="container">
          <div className="row">
            <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
              <div className="tg-sectionhead">
                <div className="tg-sectionheading">
                  <h2>Media o nas</h2>
                </div>
              </div>
            </div>
            <div className="row">
              {
                data.allMediasYaml.edges.map(({ node: { media: { name, image, title, url, description } } }, index) => {
                  const imageUrl = `images/mediaAboutUs/${image}.png`;
                  return (
                    <div className="col-sm-6 col-md-4 media-card" key={index}>
                      <div className="thumbnail">
                        <img src={imageUrl} alt={name} />
                        <div className="caption">
                          <h3 className="media-card__header"><a href={url} target="_blank" rel="noopener noreferrer">{title}</a></h3>
                          <p>{description}</p>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>)}
  />
)

export default MediaAboutUs