// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-speaker-js": () => import("../src/templates/Speaker.js" /* webpackChunkName: "component---src-templates-speaker-js" */),
  "component---src-templates-lecture-js": () => import("../src/templates/Lecture.js" /* webpackChunkName: "component---src-templates-lecture-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-c-4-p-js": () => import("../src/pages/c4p.js" /* webpackChunkName: "component---src-pages-c-4-p-js" */),
  "component---src-pages-call-4-papers-js": () => import("../src/pages/call4papers.js" /* webpackChunkName: "component---src-pages-call-4-papers-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

