import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import Header from "../components/header/Header"
import Main from "../components/main/Main"
import Footer from "../components/footer/Footer"

import "../../static/css/preloader.css";
import "../../static/css/bootstrap.min.css";
import "../../static/css/normalize.css";
import "../../static/css/font-awesome.css";
import "../../static/css/icomoon.css";
import "../../static/css/photoswipe.min.css";
import "../../static/css/owl.carousel.min.css";
import "../../static/css/default-skin.css";
import "../../static/css/animate.min.css";
import "../../static/css/transitions.css";
import "../../static/css/main.css";
import "../../static/css/color-green.css";
import "../../static/css/responsive.css";

const IndexPage = () => (
  <Layout>
    <SEO title="BB Days 4 IT :: 9 - 15 września 2019" />
    <Header />
    <Main />
    <Footer />
  </Layout>
)

export default IndexPage
