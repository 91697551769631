import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


class Counter extends Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
  query {
    allStatsYaml {
      edges {
        node {
          stat {
            number
            description
          }
        }
      }
    }
  }
`}
        render={data => (
          <section id="tg-stats" className="tg-haslayout tg-sectionspace">
            <div className="container-stat">
              <div className="row">
                <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
                  <div className="tg-sectionhead">
                    <div className="tg-sectionheading">
                      <h2>Festiwal w liczbach</h2>
                    </div>
                  </div>
                </div>
                <div className="container-stat">
                  <div className="row text-center tg-upcomingeventcounter-stat">
                    {
                      data.allStatsYaml.edges.map(({ node: { stat: { number, description } } }, index) => {
                        let suf = '';
                        let sep = '';
                        let cellStyle = 'tg-eventcounter col-md-4 col-sm-4 col-xs-6 col-lg-2 col-lg-2 col-lg-5ths';
                        if (index === 9) {
                          suf = ' zł'
                          sep = ' ';
                          cellStyle = 'tg-eventcounter col-md-12 col-sm-12 col-xs-6 col-lg-2 col-lg-2 col-lg-5ths'
                        }
                        let didShow = false
                        function onChange(isVisible, start) {
                          if (isVisible && !didShow) {
                            didShow = true;
                            start();
                          }
                        }
                        return (
                          <div className={cellStyle} key={index}>
                            <CountUp end={number} suffix={suf} separator={sep}>
                              {({ countUpRef, start }) => (
                                <div>
                                  <VisibilitySensor onChange={(v) => onChange(v, start)} >
                                    <span ref={countUpRef} />
                                  </VisibilitySensor>
                                </div>
                              )}
                            </CountUp>
                            <span>{description}</span>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>)}
      />
    )
  }
}
export default Counter