import React from 'react'
import { StaticQuery, graphql } from "gatsby"

const Testimonial = () => (
  <StaticQuery
    query={graphql`
    query {
      allOpinionsYaml {
        edges {
          node {
            opinion {
              description
            }
          }
        }
      }
    }
  `}
    render={data => (
      <section id="tg-opinions" className="tg-sectionspace tg-haslayout opinion-section">
        <div className="tg-haslayout">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
                <div className="tg-sectionhead">
                  <div className="tg-sectionheading">
                    <h2>Opinie uczestników</h2>
                  </div>
                </div>
              </div>

              <div className="owl-carousel opinion-slider container">

                {
                  data.allOpinionsYaml.edges.map(({ node: { opinion: { description } } }, index) => {
                    return (
                      <div className="opinion-slider__card" key={index}>
                        <img className="quote-top" src="images/quote.png" alt="quote"/>
                        <div>
                          {description}
                        </div>
                        <img className="quote-bottom" src="images/quote.png" alt="quote"/>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>)}
  />
)

export default Testimonial