import React from 'react';
import { graphql, Link } from 'gatsby';

import "../../static/css/preloader.css";
import "../../static/css/bootstrap.min.css";
import "../../static/css/normalize.css";
import "../../static/css/font-awesome.css";
import "../../static/css/icomoon.css";
import "../../static/css/photoswipe.min.css";
import "../../static/css/owl.carousel.min.css";
import "../../static/css/default-skin.css";
import "../../static/css/animate.min.css";
import "../../static/css/transitions.css";
import "../../static/css/main.css";
import "../../static/css/color-green.css";
import "../../static/css/responsive.css";


const Speaker = (props) => {
  const { title, speakers, description, duration, workshop } = extractPropsFromData(props);

  return (
    <div className="lecture-page">
      <Link className="tg-btn -back-to" to={'/'}>Wróc do prezentacji</Link>
      <div className="lecture">
        <div className="lecture__speaker">
          {
            speakers.map((speaker, idx) => {
              const imageUrl = `/images/speakers/${speaker}.jpg`;
              return (
                <a key={`speaker-${idx}`} className="speaker__avatar" href={`/${speaker}`}>
                  <img src={imageUrl} alt={speaker}></img>
                </a>
              )
            })
          }
        </div>
        <div className="lecture__info">
          <h2>{title}</h2>
        </div>
        {
          workshop &&
            <div style={{width: '65%'}}>
              <h6 className="text-muted text-warning">* Zapisy na warsztat przy rejestracji</h6>
              <h3 className="lecture__duration">Limit: 20 osób</h3>
            </div>
        }
        {
          duration &&
          <h4 className="lecture__duration">({duration})</h4>
        }
        <p className="lecture__description" dangerouslySetInnerHTML={{__html: description}}></p>
      </div>
    </div>
  )
}

const extractPropsFromData = ({ data }) => {
  const { allLecturesYaml: { edges } } = data;
  const firstEdge = edges[0];
  const { lecture: { title, speakers, description, duration, workshop } } = firstEdge.node;
  return { title, speakers, description, duration, workshop };
}

export const query = graphql`
  query($lectureId: String!) {
    allLecturesYaml(filter: { lecture: { id: { eq: $lectureId}}}) {
      edges {
        node {
          lecture {
            title
            duration
            description
            speakers
            workshop
          }
        }
      }
    }
  }
`
export default Speaker;
