import React from 'react'
import Layout from "../components/Layout"
import SEO from "../components/Seo"

class CallForPapersPage extends React.PureComponent {
  constructor(props) {
    super(props)
    const windowGlobal = typeof window !== 'undefined' && window;

    if (windowGlobal) {
      windowGlobal.location.href = "https://selleo.typeform.com/to/dUvP4y";
    }
  }

  render() {
    return (
      <Layout>
        <SEO title="Call for papers" />
      </Layout>
    );
  }
}

export default CallForPapersPage
