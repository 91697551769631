import React from 'react'
import ReactModal from 'react-modal';
import PrivacyPolicy from '../main/PrivacyPolicy';

ReactModal.setAppElement('#___gatsby');

class BottomBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      selectedModal: null
    }
  };

  handleOpenModal = (param) => {
    this.setState({ showModal: true, selectedModal: param });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <div className="tg-footerbar">
        <a id="tg-btnscrolltop" className="tg-btnscrolltop" href="#"><i className="icon-chevron-up"></i></a>
        <div className="container">
          <div className="row">
            <ul className="tg-socialicons pull-right">
              <li className="tg-facebook"><a href="https://www.facebook.com/bbdays4.it"><i className="fa fa-facebook"></i></a></li>
              <li className="tg-twitter"><a href="https://twitter.com/bbdays4"><i className="fa fa-twitter"></i></a></li>
            </ul>
            <p className="tg-copyrights pull-left">
              2019-2020 wszelkie prawa zastrzeżone © BBdays4IT|&nbsp;
              <PrivacyPolicy />
               | <a href="https://confcodeofconduct.com/index-pl.html" target="_blank" rel="noopener noreferrer">Konferencyjny kodeks postępowania</a>
               | <a href="mailto:kontakt@bbdays4.it">kontakt@bbdays4.it</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}


export default BottomBar
