import React, { Component } from 'react'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isToggled: false
    };
  }

  componentDidMount() {
    const elements = document.querySelectorAll('.tg-navigation ul li a')
    elements.forEach(element => element.addEventListener('click', () => this.toggleCollapse(false)))
  }

  componentWillUnmount() {
    const elements = document.querySelectorAll('.tg-navigation ul li a')
    elements.forEach(element => element.removeEventListener('click', this.toggleCollapse))
  }

  openBlank(url) {
    window.open(url, '_blank');
  }

  toggleCollapse = (isToggled = !this.state.isToggled) => this.setState({isToggled})

  render() {
    const { isToggled } = this.state

    return (
      <header id="tg-header" className="tg-header tg-headervtwo">
        <div className="container">
          <div className="row">
            
              <strong className="tg-logo">
                <a href="#"><img src="images/bb_days_4_it_light.svg" alt="BB Days 4IT" /></a>
              </strong>
              <div className="tg-navigationarea col-lg-9">
                <nav id="tg-nav" className="tg-nav">
                  <div className="navbar-header">
                    <button type="button" className="navbar-toggle collapsed" aria-expanded="false" onClick={() => this.toggleCollapse()}>
                      <span className="sr-only">Menu</span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                      <span className="icon-bar"></span>
                    </button>
                  </div>
                  <div className={`collapse navbar-collapse tg-navigation ${isToggled ? 'in' : ''}`}>
                    <ul>
                      <button type="button" className="navbar-toggle__mobile" aria-expanded="false" onClick={() => this.toggleCollapse(false)}>
                        <i className="fa fa-times fa-2x"></i>
                        <span className="sr-only">Close Menu</span>
                      </button>
                      <li><a href="#body">Start</a></li>
                      <li><a href="#tg-stats">Festiwal w liczbach</a></li>
                      <li><a href="#tg-media">Media o nas</a></li>
                      <li><a href="#tg-movies">Nasze filmy</a></li>
                      <li><a href="#tg-opinions">Opinie uczestników</a></li>
                      <li><a href="#tg-sponsors">Organizatorzy</a></li>
                      <li><a href="#tg-partners">Partnerzy</a></li>
                    </ul>
                  </div>
                </nav>
              </div>
            
          </div>
        </div>
      </header>
    )
  }
}
