import React from 'react'
import Movies from "./Movies"
import Opinion from "./Opinion"
import Partnerzy from "./Partnerzy"
import SignUp from "./SignUp"
import MediaPatrons from './MediaPatrons';
import Sponsors from './Sponsors';
import MediaAboutUs from './MediaAboutUs';
import Stats from './Stats';

const Main = () => (
  <main id="tg-main" className="tg-main tg-haslayout">
    <SignUp />
    <Stats />
    <MediaAboutUs />
    <Movies />
    <Opinion />
    <Sponsors />
    <Partnerzy />
    <MediaPatrons />
  </main>
)

export default Main
