/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const scrollOffset = 984;

exports.onInitialClientRender = () => {
  setupTheme();
}

exports.onRouteUpdate = () => {
  setupTheme();
  if (isRedirectingToIndex()) {
    const parsedLocation = parsedIndexPosition();

    if (parsedLocation) {
      const [x, y] = parsedLocation

      setTimeout(() => {
        window.scrollTo(x, y + scrollOffset);
      }, 50)
    }
  }
}

const isRedirectingToIndex = () => {
  return window.location.pathname === '/';
}

const parsedIndexPosition = () => {
  return JSON.parse(sessionStorage.getItem('@@scroll|/'));
}
const setupTheme = () => {
  window.setupTheme();
}
// 182
