import React from "react"
import CookieConsent from 'react-cookie-consent';
import PrivacyPolicy from "./main/PrivacyPolicy";

const Layout = ({ children }) => (
	<div id="tg-wrapper" className="tg-wrapper tg-haslayout">
		<div id="content">
			<div className="bbdays-preloader"></div>
			{children}
			<CookieConsent
				buttonText="Akceptuje"
				cookieName="CookiesAccepted"
				buttonClasses="tg-btn cookie-button"
			>
			Strona <a href="https://www.bbdays4.it">BBdays4.it</a> zapisuje informacje w postaci ciasteczek (ang. cookies). Ciasteczka używane są do zapisywania informacji dotyczących anonimowych odwiedzin naszego serwisu oraz umożliwiają prawidłowe funkcjonowanie strony | <PrivacyPolicy />
			</CookieConsent>
		</div>
	</div>
)

export default Layout
