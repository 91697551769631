import React from 'react'

const Sponsors = () => (
  <section id="tg-sponsors" className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="row">
        <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
          <div className="tg-sectionhead">
            <div className="tg-sectionheading">
              <h2>Organizatorzy</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul className="tg-brands">
            <li className="tg-brand"><figure><a href="http://www.rekord.com.pl" target="_blank" rel="noopener noreferrer"><img src="images/sponsorzy/rekord.png" alt="Rekord" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://selleo.com/" target="_blank" rel="noopener noreferrer"><img src="images/sponsorzy/selleo.png" alt="Selleo" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://capsilon.com/" target="_blank" rel="noopener noreferrer"><img src="images/sponsorzy/capsilon.png" alt="Capsilon" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.fcnt.eu/kontakt.html" target="_blank" rel="noopener noreferrer"><img src="images/sponsorzy/fcnt.png" alt="FCNT" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.bielsko-biala.pl" target="_blank" rel="noopener noreferrer"><img src="images/sponsorzy/bielsko-biała.png" alt="Urząd Miasta Bielsko-Biała" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.ath.bielsko.pl" target="_blank" rel="noopener noreferrer"><img src="images/sponsorzy/ath.png" alt="Akademia Techniczno-Humanistyczna w Bielsku-Białej" /></a></figure></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default Sponsors
