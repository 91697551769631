import React from 'react'
import ReactModal from 'react-modal';

ReactModal.setAppElement('#___gatsby');

class PrivacyPolicy extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    }
  };

  handleOpenModal = () => {
    this.setState({ showModal: true });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    return (
      <span>
        <a href="javascript:void(0);" onClick={() => this.handleOpenModal(0)}>Polityka prywatności</a>
        <ReactModal
          isOpen={this.state.showModal}
          contentLabel="onRequestClose Example"
          onRequestClose={this.handleCloseModal}
          shouldCloseOnOverlayClick={true}
          className="react-model__content"
          overlayClassName="react-model__overlay"
        >
          <button className="react-model__close-button" onClick={this.handleCloseModal}>&times;</button>
          <div>
            <div className="tg-sectionheading">
              <h2>Polityka prywatności</h2>
            </div>

            <h3>Wprowadzenie</h3>
            <p>Ochrona danych osobowych jest jednym z ważnych aspektów w działalności Fundacjii Centrum Nowych Technologii.  Niniejsza polityka prywatności informuje o sprawach związanych z przetwarzaniem danych osobowych, a w tym o sposobach ich zbierania i wykorzystywania, a także o prawach podmiotów danych z tym związanych. Niniejsza polityka uwzględnia obowiązujące przepisy prawa, a w tym Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27.04.2016r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (tzw. „RODO”) oraz Ustawę o ochronie danych osobowych z dn. 18 maja 2018r. (Dz.U. 2018 poz. 1000). (tzw. „UODO”)</p>

            <p>Zapewniamy, że dokładamy należytej staranności aby zapewnić ochronę interesów osób, których dane osobowe przetwarzamy. W szczególności zapewniamy, że przetwarzamy dane zgodnie z RODO, w oznaczonych, zgodnych z prawem celach o których Państwa informujemy. Dane osobowe są merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz przechowywane, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania i wymagań przepisów.</p>

            <h3>Czym są dane osobowe i co oznacza ich przetwarzanie?</h3>
            <p>Dane osobowe oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej. Przetwarzaniem danych osobowych jest w zasadzie każda czynność na danych osobowych, niezależnie od tego, czy jest dokonywana w sposób zautomatyzowany czy nie, np. zbieranie, przechowywanie, utrwalanie, porządkowanie, modyfikowanie, przeglądanie, wykorzystywanie, udostępnianie, ograniczanie, usuwanie lub niszczenie. Fundacja Centrum Nowych Technologii  przetwarza dane osobowe w różnych celach, przy czym w zależności od celu, zastosowanie mogą mieć różne sposoby zbierania, podstawy prawne przetwarzania, wykorzystywania, ujawniania oraz okresy przechowywania.</p>

            <h3>Kiedy niniejsza Polityka prywatności ma zastosowanie?</h3>
            <p>Niniejsza polityka prywatności ma zastosowanie do wszystkich przypadków, w których Fundacja Centrum Nowych Technologii jest administratorem danych osobowych i przetwarza dane osobowe. Dotyczy to zarówno sytuacji, w której przetwarzamy dane osobowe pozyskane bezpośrednio od podmiotu danych, jak też w przypadku gdy dane osobowe pozyskaliśmy z innych źródeł niż od osoby, której dane dotyczą.</p>

            <h3>Administrator danych osobowych</h3>
            <p>Administratorem danych osobowych jest: Fundacja Centrum Nowych Technologii.  z siedzibą przy ul. K. Gałczyńskiego 31, 43-300 Bielsko-Biała, zarejestrowana w rejestrze przedsiębiorców przez Sąd Rejonowy w Bielsku-Białej pod numerem KRS 0000257174 NIP 9372490531. W sprawach związanych z ochroną danych osobowych należy się kontaktować za pośrednictwem e-maila pod adres: lub też w formie pisemnej na adres siedziby Fundacjii Centrum Nowych Technologii.</p>

            <h3>W jaki sposób pozyskujemy dane i jakiego rodzaju dane osobowe zbieramy?</h3>
            <p>Dane osobowe, których jesteśmy administratorem, są przez nas uzyskiwane bezpośrednio od osób, których te dane dotyczą. Dane te staramy się pozyskiwać tylko wówczas, gdy jest to rzeczywiście niezbędne do świadczenia danej usługi.</p>

            <p>Zakres przetwarzanych danych osobowych jest adekwatny do celów przetwarzania i obejmuje najczęściej: imię i nazwisko, nazwę pracodawcy, stanowisko osoby kontaktowej, numer telefonu, adres e-mail lub inne służbowe dane kontaktowe. Dane osobowe kontaktów biznesowych mogą być ujawnione stałym współpracownikom Fundacjii Centrum Nowych Technologii  stanowiącym jej siły handlowe i marketingowe wykorzystywane przez nich w celu prowadzenia działalności Fundacjii Centrum Nowych Technologii.</p>

            <p>W żadnym wypadku nie prosimy o udostępnianie nam danych osobowych uznanych jako szczególne kategorie danych osobowych (art. 9 RODO). W przypadku podania przez Państwa takich informacji będzie to oznaczać wyraźną zgodę na zbieranie przez nas i wykorzystywanie takich informacji w sposób określony w niniejszym dokumencie lub określony w miejscu, gdzie informacje te zostały ujawnione.</p>

            <h3>Cele przetwarzania oraz podstawa prawna przetwarzania</h3>
            <p>Przetwarzamy dane w różnych sytuacjach biznesowych opisanych w naszej polityce prywatności. Poniżej przedstawiamy informacje na temat celów przetwarzania oraz podstaw prawnych. Konkretny cel i podstawa przetwarzania są podawane w odrębnej klauzuli informacyjnej kierowanej do osób, których dane dotyczą.</p>

            <p>Podstawą przetwarzania danych osobowych w celu zawarcia i wykonania umowy oraz świadczenia usług zgodnie z umową jest niezbędność przetwarzania danych do zawarcia i wykonywania umowy.</p>

            <p>Dla celów marketingowych Administratora (np. wysyłka newslettera) - w tym w celach analitycznych – podstawą jest udzielona zgoda, a w niektórych przypadkach (np. kontaktu w celu uzyskania zgody) także uzasadniony interes administratora, np. optymalizacja procesów obsługi.</p>

            <p>Na podstawie prawnie uzasadnionego interesu administratora przetwarzamy dane w celu ustalenia, dochodzenia lub obrony przed ewentualnymi roszczeniami związanymi z zawartą umową lub świadczonymi usługami. Uzasadnionym interesem administratora jest możliwość dochodzenia przez niego roszczeń lub obrona przez nimi.</p>

            <p>W celu oferowania przez nas produktów i usług bezpośrednio (marketing bezpośredni), w tym dobierania ich pod kątem potrzeb – podstawą jest niezbędność przetwarzania do realizacji prawnie uzasadnionego interesu administratora; uzasadnionym interesem administratora jest marketing bezpośredni usług/produktów Administratora.</p>

            <p>Osoby fizyczne odwiedzające strony internetowe Fundacjii Centrum Nowych Technologii lub korzystające z naszych usług za pośrednictwem serwisów internetowych, mają kontrolę nad danymi osobowymi, które nam podają. Serwisy te ograniczają zbieranie i wykorzystywanie informacji o ich użytkownikach do niezbędnego minimum, wymaganego do świadczenia na ich rzecz usług na pożądanym poziomie.</p>

            <h3>Okres, przez który dane będą przechowywane</h3>

            <p>Dane osobowe, które przetwarzamy w celu wykonania umowy - będziemy przechowywać do momentu wygaśnięcia umowy, przedawnienia roszczeń z tytułu umowy lub do momentu wygaśnięcia obowiązku przechowywania danych, wynikającego z przepisów prawa, w szczególności obowiązku przechowywania dokumentów księgowych dotyczących umowy.
            Tam, gdzie przetwarzamy dane osobowe w oparciu o usprawiedliwiony interes Administratora, dane osobowe nie będą przetwarzane dla określonego celu, jeżeli zgłosisz sprzeciw wobec takiego przetwarzania.
      Jeżeli wyraziłeś na to zgodę, Twoje dane będą przetwarzane zgodnie z Twoją wolą, aż do czasu odwołania zgody. Przykładowo, jeżeli wyraziłeś na to odrębną zgodę, będziemy wysyłać informacje marketingowe w postaci newslettera. Wszystkie zgody są dobrowolne i w każdej chwili mogą być wycofane, bez skutków wobec przetwarzania które nastąpiło do czasu wycofania.</p>

            <h3>Prawa osoby, której dane dotyczą i sposób ich realizacji</h3>

            <p>Osoby fizyczne mają określone prawa dotyczące swoich danych osobowych, a Fundacja Centrum Nowych Technologii jako administrator danych odpowiada za ich realizację. Mając na uwadze art.13 RODO informujemy że,
            przysługuje Ci prawo dostępu do Twoich danych oraz prawo żądania ich sprostowania, usunięcia, ograniczenia przetwarzania. Jeśli podstawą przetwarzania Twoich danych osobowych jest prawnie uzasadniony interes Administratora, możesz wnieść sprzeciw wobec przetwarzania Twoich danych osobowych.
            W przypadku korzystania z usług wymagających zalogowania na indywidualne konto, użytkownik może również dokonać zmiany swoich danych osobowych samodzielnie, korzystając z odpowiedniej opcji, jeżeli taka jest dostępna w serwisie.
            W zakresie, w jakim podstawą przetwarzania Twoich danych osobowych jest zgoda, masz prawo cofnięcia zgody. Cofnięcie zgody nie ma wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem.
            W zakresie, w jakim Twoje dane są przetwarzane w celu zawarcia i wykonywania umowy/ świadczenia usług lub przetwarzane na podstawie zgody, przysługuje Ci także prawo do przenoszenia danych osobowych. W takiej sytuacji otrzymasz od nas swoje dane osobowe w ustrukturyzowanym, powszechnie używanym formacie, nadającym się do odczytu maszynowego. Możesz przesłać te dane innemu administratorowi danych.
            Przysługuje Ci również prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych, którym jest Prezes Urzędu Ochrony Danych Osobowych, gdy uznasz, iż przetwarzanie Twoich danych osobowych narusza przepisy RODO
      Zastrzegamy sobie prawo realizacji w/w uprawnień po pozytywnym zweryfikowaniu tożsamości osoby ubiegającej się o dokonanie danej czynności.</p>

            <p>W przypadku wszelkich pytań, uwag, skarg lub wniosków dotyczących przetwarzania Twoich danych osobowych, w tym także w celu realizacji swoich praw, prosimy o kontakt mailowy pod adresem: . W ten sam sposób prosimy zgłaszać wszelkie pytania, zastrzeżenia, wątpliwości czy też skargi dotyczące treści niniejszej polityki prywatności lub sposobu w jaki przetwarzamy dane osobowe.</p>

            <p>Wszelka otrzymana korespondencja zostanie rozpatrzona zgodnie z jednolitą procedurą i udzielimy na nią odpowiedzi.</p>

            <h3>Automatyczne przetwarzanie danych osobowych i profilowanie</h3>

            <p>Informacje, które gromadzimy w związku z korzystaniem z naszych Serwisów mogą być przetwarzane w sposób zautomatyzowany (w tym w formie profilowania), jednakże nie będzie to wywoływać wobec osoby fizycznej żadnych skutków prawnych lub w podobny sposób istotnie wpływać na jej sytuację. Nie przetwarzamy szczególnych kategorii danych w rozumieniu RODO, a na potrzeby profilowania nie przetwarzamy również żadnych danych wrażliwych. Profilujemy w celach marketingowych, tj. dopasowania oferty marketingowej do preferencji użytkownika.</p>

            <p>Odwiedzając nasz serwis internetowy podlegacie Państwo zautomatyzowanemu profilowaniu w celach statystycznych i marketingowych. Polega ono na zbieraniu informacji i analizowaniu zachowań (m.in odwiedzanych stron, w serwisie www) poprzez narzędzia takie jak Google Analitycs. Do wyżej wymienionych usługodawców mogą być przekazywane takie dane jak: numer IP, obecnie wykorzystywany system operacyjny, obecnie wykorzystywana rozdzielczość ekranu, Informacje o urządzeniu na którym Państwo korzystacie z serwisu.</p>

            <h3>Cookies</h3>
            <p>W ograniczonym zakresie możemy zbierać dane osobowe automatycznie za pośrednictwem plików cookies znajdujących się na naszych stronach internetowych. Cookies to małe pliki tekstowe zapisywane na komputerze użytkownika lub innym urządzeniu mobilnym, w czasie korzystania przez niego z serwisów internetowych. Pliki te służą m.in. korzystaniu z różnych funkcji przewidzianych na danej stronie internetowej lub potwierdzeniu, że dany użytkownik widział określone treści z danej witryny internetowej. Wśród plików cookies wyróżnić możne te, które są niezbędne do działania serwisów REKORD SI. Należące do tej kategorii pliki cookies wykorzystywane są, aby zapewnić:</p>

            <ul>
              <li>utrzymanie sesji użytkownika;</li>
              <li>zapisanie stanu sesji użytkownika;</li>
              <li>umożliwienie autoryzacji z wykorzystaniem Serwisu logowania</li>
              <li>monitoring dostępności usług.</li>
            </ul>
            <p>Kolejna kategoria plików cookies, to pliki, które wprawdzie nie są niezbędne do korzystania z Serwisów, ale ułatwiają korzystanie z nich. Korzystamy też przy tym z usług podmiotów trzecich, które wykorzystują pliki cookies. Do tych podmiotów, w dacie sporządzenia niniejszego dokumentu zaliczamy:</p>

            <ul>
              <li>Google Analytics (więcej informacji oraz dodatek do przeglądarki blokujący Google Analytics: tools.google.com);</li>
              <li>Cookies wykorzystywane są wówczas w następujących celach:monitorowanie ruchu na naszych stronach WWW;</li>
              <li>zbieranie anonimowych, zbiorczych statystyk, które pozwalają nam zrozumieć, w jaki sposób użytkownicy korzystają z naszej strony internetowej i umożliwiają nam ciągłe doskonalenie naszych produktów;</li>
              <li>kontrolowanie jak często pokazywana jest użytkownikom wybrana treść;</li>
              <li>badanie zapisów na newslettery lub deklaracji uczestnictwa w wydarzeniach;</li>
            </ul>
            <strong>Użytkownik może zarządzać plikami cookies wykorzystywanymi przez Fundację Centrum Nowych Technologii lub przez jakichkolwiek innych zewnętrznych dostawców, zmieniając ustawienia swojej przeglądarki internetowej. Fundacja Centrum Nowych Technologii  zastrzega jednocześnie, że po odrzuceniu plików cookies niektóre funkcje oferowane przez nasze Serwisy mogą nie działać prawidłowo, a w niektórych przypadkach wiąże się to nawet z całkowitą niemożnością korzystania z wybranych usług. Dotyczy to w szczególności części serwisu wymagającej logowania.</strong>

            <h3>Logi dostępowe</h3>
            <p>Zbieramy informacje dotyczące korzystania z Serwisów przez jego użytkowników oraz ich adresy IP w oparciu o analizę logów dostępowych. Informacje te wykorzystujemy w diagnozowaniu problemów związanych z pracą serwera, analizą ewentualnych naruszeń bezpieczeństwa oraz w zarządzaniu serwisami WWW. Adres IP jest wykorzystywany przez nas nadto w celach statystycznych, tj. do gromadzenia i analizy danych demograficznych osób odwiedzających witrynę (np. informacji o regionie, z którego nastąpiło połączenie). Na podstawie uzyskanych w podany wyżej sposób informacji w szczególnych przypadkach sporządzane są zbiorcze, ogólne zestawienia statystyczne. Obejmują one zwykle informacje o oglądalności serwisu. Zestawienia te nie zawierają jednak, co podkreślamy, żadnych danych pozwalających na identyfikację (określenie tożsamości) danego użytkownika Serwisu.</p>

            <p>Informujemy jednocześnie, że możemy zostać zobowiązani do ujawnienia informacji dotyczących numeru IP danego użytkownika Serwisu na żądanie uprawnionych do tego - w oparciu obowiązujące przepisy prawne - organów państwa w związku z prowadzonymi przez nie postępowaniami.</p>

            <h3>Zabezpieczenia danych osobowych</h3>
            <p>Udostępnione przez Państwa dane, a także wszelkie logi systemowe oraz inne dane generowane automatycznie w ramach korzystania z naszych serwisów są przechowywane są w bezpiecznej lokalizacji, na naszych serwerach oraz przetwarzane z zastosowaniem wymaganych przepisami prawa środków technicznych i organizacyjnych, zapewniających ich ochronę odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, zabezpieczane przed ich przetwarzaniem z naruszeniem obowiązujących przepisów.</p>

            <h3>Zmiany w polityce prywatności</h3>
            <p>Niniejsza polityka prywatności jest aktualizowana w każdej sytuacji, gdy okaże się to niezbędne lub pożądane z uwagi na: nowe przepisy prawa, wytyczne organów odpowiedzialnych za nadzór nad procesami ochrony danych osobowych, najlepsze praktyki stosowane w obszarze ochrony danych osobowych, a także w razie zmiany sposobów, celów lub podstaw prawnych przetwarzania przez nas danych osobowych.</p>

            <p>Ostatnia aktualizacja niniejszego dokumentu miała miejsce dnia 01.04.2018 r.</p>
          </div>
        </ReactModal>
      </span>
    );
  }
}

export default PrivacyPolicy