import React from 'react'
// import Contact from './Contact'
import BottomBar from './BottomBar'

const Footer = () => (
  <footer id="tg-footer" className="tg-footer tg-haslayout">
    <BottomBar />
  </footer>
)

export default Footer