import React from 'react'

const SignUp = () => (
  <section className="tg-haslayout tg-bgsignup">
    <div className="container signup__body">
      <div className="row signup__row">
        <div className="tg-signupnewsletter">
          <div className="tg-signupdetail">
            <div className="section-newsletter">
              <h1 className="newsletter-lg-head">BBDAYS4IT 2019</h1>
              <h1 className="newsletter-m-head">9-15 września</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default SignUp
