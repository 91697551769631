import React from 'react';
import { graphql, Link } from 'gatsby';

import "../../static/css/preloader.css";
import "../../static/css/bootstrap.min.css";
import "../../static/css/normalize.css";
import "../../static/css/font-awesome.css";
import "../../static/css/icomoon.css";
import "../../static/css/photoswipe.min.css";
import "../../static/css/owl.carousel.min.css";
import "../../static/css/default-skin.css";
import "../../static/css/animate.min.css";
import "../../static/css/transitions.css";
import "../../static/css/main.css";
import "../../static/css/color-green.css";
import "../../static/css/responsive.css";

const Speaker = (props) => {
  const { image, role, name, url, description } = extractPropsFromData(props);

  const imageUrl = `/images/speakers/${image}.jpg`;
  return (
    <div className="speaker-page">
      <Link className="tg-btn -back-to" to={'/'}>Wróć do prelegentów</Link>
      <div className="speaker">
        <img className="speaker__avatar" src={imageUrl} alt={name}></img>
        <div style={{width: '65%'}}>
          <h1>{name}</h1>
          <h3>{role}</h3>
        </div>
        <div className="speaker__contact">
          <span className="linkedin">
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img src="/images/linkedin.png" alt="Linkedin"></img>
            </a>
          </span>
        </div>
          <p className="speaker__description" dangerouslySetInnerHTML={{__html: description}}></p>
      </div>
    </div>
  )
}

const extractPropsFromData = ({ data }) => {
  const { allSpeakersYaml: { edges } } = data;
  const firstEdge = edges[0];
  const { speaker: { image, role, name, url, description } } = firstEdge.node;
  return { image, role, name, url, description };
}

export const query = graphql`
  query($speakerSlug: String!) {
    allSpeakersYaml(filter: { speaker: { image: { eq: $speakerSlug}}}) {
      edges {
        node {
          speaker {
            name
            image
            role
            url
            description
          }
        }
      }
    }
  }
`

export default Speaker;
