import React from 'react'

const Partners = () => (
  <section id="tg-partners" className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="row">
        <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
          <div className="tg-sectionhead">
            <div className="tg-sectionheading">
              <h2>Partnerzy</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul className="tg-brands">
            <li className="tg-brand"><figure><a href="https://www.pitneybowes.com" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/pitney_bowes.png" alt="Pitney Bowes" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://buddy.works/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/buddy.png" alt="Buddy" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://4experience.co/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/4experience.png" alt="4Experience" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.next.biz.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/next.png" alt="Next" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.versum.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/versum.png" alt="Versum" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.davinci-studio.com" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/da_vinci_studio.png" alt="Da Vinci Studio" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://jantar.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/jantar.png" alt="Jantar" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://bbdays4it.inelo.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/inelo.png" alt="Inelo" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.jetbrains.com" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/jetbrains.png" alt="JetBrains" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://bielskobiala.geminipark.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/gemini-park.png" alt="Gemini Park Bielsko-Biała" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.cci.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/rihip.png" alt="Regionalna Izba Handlu i Przemysłu w Bielsku-Białej" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.meetup.com/Bielsko-Biala-JUG/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_bbjug.png" alt="BB JUG" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://codebb.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_codebb.png" alt="CodeBB" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.meetup.com/meet-js-Bielsko-Biala/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_meetjs_bb.png" alt="Meet.js BB" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://mikstura.it/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_mikstura.png" alt="Mikstura.IT" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.startuppodbeskidzie.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/spolecznosc_startup_podbeskidzie.png" alt="Startup Podbeskidzie" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.wsi.edu.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/wsiz.png" alt="Wyższa Szkoła Informatyki i Zarządzania w Bielsku-Białej" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://technikum.wsi.edu.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/pti.png" alt="Prywatne Technikum Informatyczne" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.ath.bielsko.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/reset.png" alt="Reset ATH" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.fablab24.pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/fab-lab.png" alt="Fab Lab" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://arrsa.pl/pl/" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/arr.png" alt="Agencja Rozwoju Regionalnego S.A. Bielsko-Biała" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.bcc.org.pl" target="_blank" rel="noopener noreferrer"><img src="images/partnerzy/bcc.png" alt="Business Centre Club" /></a></figure></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default Partners
