import React from 'react'

const MediaPatrons = () => (
  <section className="tg-sectionspace tg-haslayout">
    <div className="container">
      <div className="row">
        <div className="col-xs-offset-0 col-xs-12 col-sm-offset-0 col-sm-12 col-md-offset-2 col-md-8 col-lg-offset-2 col-lg-8">
          <div className="tg-sectionhead">
            <div className="tg-sectionheading">
              <h2>Patronat Medialny</h2>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <ul className="tg-brands">
            <li className="tg-brand"><figure><a href="https://www.radiobielsko.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/radio-bielsko.png" alt="Radio Bielsko" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://beskidzka24.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/beskid.png" alt="Beskidzka24" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.bielsko.biala.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/bielsko-biala.png" alt="Miasto Bielsko Biała" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.bielsko.info/" target="_blank" rel="noopener noreferrer"><img src="images/media/bielsko-info.png" alt="Bielsko Info" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://wyborcza.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/gazeta-wyborcza.png" alt="Gazeta Wyborcza" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://dziennikzachodni.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/dziennik-zachodni.png" alt="Dziennik Zachodni" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://www.kurier-bb.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/kurier-bb.png" alt="Kurier BB" /></a></figure></li>
            <li className="tg-brand"><figure><a href="http://gazetabeskidzka.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/gazeta-beskidzka.png" alt="Gazeta Beskidzka" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://redakcjabb.pl" target="_blank" rel="noopener noreferrer"><img src="images/media/redakcja-bb.png" alt="redakcjaBB" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://www.ilovebb.pl/sklep/" target="_blank" rel="noopener noreferrer"><img src="images/media/ilovebb.png" alt="I love BB" /></a></figure></li>
            <li className="tg-brand"><figure><img src="images/media/43300.png" alt="43300" /></figure></li>
            <li className="tg-brand"><figure><a href="https://dailyweb.pl/" target="_blank" rel="noopener noreferrer dofollow"><img src="images/media/daily-web.png" alt="Daily Web" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://webmastah.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/web-mastah.png" alt="WebMastah" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://crossweb.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/crossweb.png" alt="Crossweb" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://dotnetomaniak.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/dotnet-maniak.png" alt="Dotnet Maniak" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://programistamag.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/programista.png" alt="Magazyn programista" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://mrdev.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/mr-dev.png" alt="Mr Dev" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://gamedevjs.pl/" target="_blank" rel="noopener noreferrer"><img src="images/media/game-dev.png" alt="GameDev.js" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://geek.justjoin.it/" target="_blank" rel="noopener noreferrer"><img src="images/media/just-geek.png" alt="Just Geek IT" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://techevents.pl" target="_blank" rel="noopener noreferrer"><img src="images/media/tech-events.png" alt="Tech Events" /></a></figure></li>
            <li className="tg-brand"><figure><a href="https://bulldogjob.pl" target="_blank" rel="noopener noreferrer"><img src="images/media/bulldogjob.png" alt="Bulldog Job" /></a></figure></li>
          </ul>
        </div>
      </div>
    </div>
  </section>
)

export default MediaPatrons
